import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SimpleSection from "../components/sections/simpleSection/SimpleSection";
import BreadCrumps from "../ui/breadCrumps/BreadCrumps";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { Link } from "gatsby";
import ButtonLinkWrapper from "../ui/btnLinkWrapper/ButtonLinkWrapper";
import MyButton from "../ui/button/MyButton";
import Arrow from "../ui/btnArrow/Arrow";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Newspage = ({ data }) => {
  return (
    <>
      <HelmetDatoCms seo={data.page.seo} favicon={data.site.favicon} />
      <Layout>
        <BreadCrumps title={data.page.sectiontitle} />
        <SimpleSection
          id="newspage"
          sectionTitle={data.page.sectiontitle}
          sectionTitleDesc={data.page.sectiondescription}
          sectiontitleBtnTxt=""
          sectiontitleBtnUrl={false}
          isBlackBg={false}
        >
          <div style={{ padding: `40px` }} className="news-grid">
            {data.allPosts.edges.map(({ node: post }, index) => {
              return (
                <div key={index} className="news-grid-item">
                  <div className="post-container">
                    <div className="image-wrapper">
                      <GatsbyImage
                        image={getImage(post.coverImage.gatsbyImageData)}
                        alt={post.title + "image"}
                      />
                    </div>
                    <div className="content">
                      <Link
                        to={`/news/${post.slug}`}
                        style={{ textDecoration: "none" }}
                      >
                        <h2>{post.title}</h2>
                      </Link>
                      <p className="details">{post.excerpt}</p>
                      <Link
                        to={`/news/${post.slug}`}
                        style={{ textDecoration: "none" }}
                      >
                        <ButtonLinkWrapper>
                          <MyButton isBlackBg={true}>ДЕТАЛЬНІШЕ</MyButton>
                          <Arrow isBlackBg={true} />
                        </ButtonLinkWrapper>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </SimpleSection>
      </Layout>
    </>
  );
};

export default Newspage;

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    page: datoCmsNewspage {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      sectiontitle
      sectiondescription
    }
    allPosts: allDatoCmsPost(sort: { fields: data, order: DESC }) {
      edges {
        node {
          title
          slug
          excerpt
          coverImage {
            gatsbyImageData(width: 320)
          }
        }
      }
    }
  }
`;
